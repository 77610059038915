module.exports = {
  siteTitle: 'mocjic', // <title>
  manifestName: 'mocjic',
  manifestShortName: 'mocjic', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/avatar2.jpg',
  pathPrefix: `/`,
  authorName: 'mocjic',
  copyrightName: 'Mocjic LLC',
  heading: 'custom software development\nweb ・ mobile',
  // social
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@mocjic.com',
    },
  ],
};
